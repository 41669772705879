import React from 'react'
import { connect } from 'react-redux'
import SingleProduct from './singleProduct'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { processCollectionDetails } from 'helpers/helperFuncs'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const OwlCarousel = dynamic(() => import('react-owl-carousel'), { ssr: false })

class NewProduct extends React.Component {
  render() {
    const { selectedCurrency, productCollections, selectedLanguage } = this.props

    const col = productCollections.find((c) => c.collectionType === 'new_products')
    const newProductCollection = processCollectionDetails(col, selectedLanguage.key)

    return (
      <div className="col-lg-6">
        <div className="fb-section_title fb-section_title-3 fb-nf-product-title">
          <h2>
            {' '}
            <LazyLoadImage
              src={newProductCollection.iconUrl} alt="icon" style={{width: '30px'}}
            />
            {newProductCollection.name}
          </h2>
        </div>
        <div className="fb-banner fb-img-hover-effect">
          <Link href="/[lang]/top-sellers" as={`${selectedLanguage.key}/top-sellers`}>
            <a>
              <LazyLoadImage
                src={newProductCollection.imageUrl} alt="FB'S Banner"
              />
            </a>
          </Link>
        </div>
        <div className="fb-product_wrap fb-new_product_wrap bg-white pb-70">
          <OwlCarousel
            className="new-product-collection fb-product_active fb-new_product_active owl-carousel newProducts"
            lazyLoad
            autoplayTimeout={5000}
            margin={0}
            nav
            navContainerClass="owl-nav"
            navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
            items={2}
            responsive={{
              0: {
                items: 1,
              },
              576: {
                items: 2,
              },
              768: {
                items: 2,
              },
            }}
          >
            <SingleProduct products={newProductCollection.products} selectedCurrency={selectedCurrency} column />
          </OwlCarousel>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
    selectedCurrency: state.currency.selectedCurrency,
  }
}

export default connect(mapStateToProps, null)(NewProduct)
