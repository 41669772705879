import React from 'react'
import { connect } from 'react-redux'
import dynamic from 'next/dynamic'
import SingleProduct from './singleProduct'
import { processCollectionDetails } from 'helpers/helperFuncs'

const OwlCarousel = dynamic(() => import('react-owl-carousel'), { ssr: false })

function HotDeals(props) {
  const { selectedLanguage, selectedCurrency, productCollections = [] } = props
  const col = productCollections.find((c) => c.collectionType === 'hot_deals')
  const hotDealCollection = processCollectionDetails(col, selectedLanguage.key)

  return (
    <div className="fb-product_with_banner pb-30">
      <div className="container">
        <div className="fb-section_title">
          <h2>{hotDealCollection.name}</h2>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="fb-product_wrap bg-white pb-70">
              <OwlCarousel
                className="fb-product_active owl-carousel hotDeals"
                lazyLoad
                autoplayTimeout={5000}
                margin={0}
                nav
                navContainerClass="owl-nav"
                items={3}
                navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
                responsive={{
                  0: {
                    items: 1,
                  },
                  576: {
                    items: 2,
                  },
                  768: {
                    items: 3,
                  },
                }}
              >
                <SingleProduct products={hotDealCollection.products} selectedCurrency={selectedCurrency} column />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
    selectedCurrency: state.currency.selectedCurrency,
  }
}

export default connect(mapStateToProps)(HotDeals)
