import React from 'react'
import { connect } from 'react-redux'
import Loader from '../loader'
import SingleProduct from './singleProduct'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const OwlCarousel = dynamic(() => import('react-owl-carousel'), { ssr: false })

class FeaturedProduct extends React.Component {
  getTranslation = (productTranslations) => {
    if (!productTranslations) {
      return {}
    }

    const language = this.props.selectedLanguage
    const translation = productTranslations.filter((tx) => tx.lang === language.key)

    return translation[0] || {}
  }

  render() {
    const { selectedLanguage } = this.props
    const featuredProductCollection = this.props.productCollections.find(
      (c) => c.collectionType === 'featured_products',
    )
    if (!featuredProductCollection) {
      return <Loader />
    }
    const translation = this.getTranslation(featuredProductCollection.collectionTranslations)
    return (
      <div className="col-lg-6">
        <div className="fb-section_title fb-section_title-3 fb-nf-product-title second-child">
          <h2>
            <LazyLoadImage src={featuredProductCollection.iconUrl} alt="icon" style={{ width: '30px' }} />
            {translation.name}
          </h2>
        </div>
        <div className="fb-banner fb-img-hover-effect">
          <Link href="/[lang]/new-products" as={`/${selectedLanguage.key}/new-products`}>
            <a>
              <LazyLoadImage src={featuredProductCollection.imageUrl} alt="FB'S Banner" />
            </a>
          </Link>
        </div>
        <div className="fb-product_wrap fb-new_product_wrap bg-white pb-70">
          <OwlCarousel
            className="new-product-collection fb-product_active owl-carousel fb-new_product_active featuredProducts"
            lazyLoad
            autoplayTimeout={5000}
            margin={0}
            nav
            navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
            navContainerClass="owl-nav"
            items={2}
            responsive={{
              0: {
                items: 1,
              },
              576: {
                items: 2,
              },
              768: {
                items: 2,
              },
            }}
          >
            <SingleProduct
              products={featuredProductCollection.products}
              selectedCurrency={this.props.selectedCurrency}
              column
            />
          </OwlCarousel>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
  }
}

export default connect(mapStateToProps, null)(FeaturedProduct)
