import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import HotDeals from '@components/ui/hotDeals'
import NewProduct from '@components/ui/newProduct'
import FeaturedProduct from '@components/ui/featuredProduct'
import SpecialCollection from '@components/specialCollection'
import { getAllProductCollections } from '@store/actions/productCollectionActions'
import ProductCollectionService from '@services/productCollectionService'
import Head from 'next/head'
import { getBannerImages } from '@services/bannerImageService'
// import Slider from 'react-slick'
import Link from 'next/link'
import dynamic from 'next/dynamic'

const OwlCarousel = dynamic(() => import('react-owl-carousel'), { ssr: false })

function Home(props) {
  const { selectedLanguage, selectedCurrency, bannerImages, products = [] } = props

  const filteredBanners = useMemo(() => {
    return bannerImages.filter((banner) => banner.lang === selectedLanguage.key)
  }, [bannerImages, selectedLanguage?.key])

  return (
    <>
      <Head>
        <title>
          {selectedLanguage.key === 'en'
            ? 'Building materials online shop & delivery for professionals and DIYers | Batibau'
            : 'Online nakupování a dodávky stavebních materiálů | Batibau'}
        </title>
        <link rel="canonical" href={`https://batibau.cz/${selectedLanguage.key}`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={
            selectedLanguage.key === 'en'
              ? 'Building materials: High quality products, best prices, delivery within 24 hours'
              : 'Stavební materiály: Vysoce kvalitní výrobky, nejlepší ceny, dodání do 24 hodin'
          }
        />
        <meta
          property="og:image"
          content="https://batibau.cz/assets/images/logo/batibau/BatiBau_logo_png-Black_Long%20VERSION.png"
        />
      </Head>
      <div className="slider-with-banner my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                className="owl-carousel"
                loop
                lazyLoad
                autoplay
                autoplayTimeout={5000}
                margin={0}
                nav
                navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
                navContainerClass="owl-nav"
                items={1}
              >
                {filteredBanners.map((v) => {
                  return (
                    <Link
                      href="/[lang]/[menuSlug]"
                      as={`/${selectedLanguage.key}/${v.category.translations[0].slug}`}
                      key={v.id}
                    >
                      <a className="block m-auto w-100">
                        <img src={v.imageUrl} alt={v.name} style={{ maxHeight: 400, objectFit: 'cover' }} />
                      </a>
                    </Link>
                  )
                })}
                {!filteredBanners.length && <div className="home-bg"></div>}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>

      <HotDeals productCollections={products} selectedCurrency={selectedCurrency} />

      <SpecialCollection productCollections={products} selectedCurrency={selectedCurrency} />

      <div className="fb-banner_with-new_product pb-60">
        <div className="container">
          <div className="row">
            <NewProduct productCollections={products} selectedCurrency={selectedCurrency} />
            <FeaturedProduct productCollections={products} selectedCurrency={selectedCurrency} />
          </div>
        </div>
      </div>
    </>
  )
}

export async function getServerSideProps({ query, req }) {
  let langCode = 'en'
  if (req.cookies.lang) {
    try {
      const lang = JSON.parse(req.cookies.lang)
      langCode = lang.key
    } catch (e) {
      console.error(e)
    }
  }
  const { productCollections } = await ProductCollectionService.getAll(langCode)
  const { bannerImages = [] } = await getBannerImages()

  return { props: { products: productCollections, bannerImages, query } }
}

const mapStateToProps = (state) => {
  return {
    modalState: state.modal,
    selectedLanguage: state.language.selectedLanguage,
    selectedCurrency: state.currency.selectedCurrency,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    retrieveProducts: () => dispatch(getAllProductCollections()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
