import React, { Component, memo } from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import { openModal } from '@store/actions/modal'
import Stars from 'react-stars'
import { addToCart } from '@store/actions/cart'
import parse from 'html-react-parser'
import { addProductToWishList } from '@store/actions/wishlist'
import Translation from '@translation'
import { getPrimaryImage, calculateDiscount, scrollToTop, processProductDetails, getPrice } from 'helpers/helperFuncs'
import { toast } from 'react-toastify'
import { captureException } from 'helpers/sentry'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class SingleProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  addToWishlist = async (id) => {
    const { selectedLanguage } = this.props
    const T = Translation(selectedLanguage)
    try {
      await this.props.doAddToWishlist({ productId: id, units: 1 })

      toast.success(T.added_to_wishlist)
    } catch (err) {
      toast.warning(T.wishlist_item_already_in_list)
      captureException(err)
    }
  }

  async addToCart(id) {
    const { selectedLanguage } = this.props
    const T = Translation(selectedLanguage)
    const productId = id
    const product = {
      units: 1,
      productId,
    }

    const { cartProducts } = this.props

    const alreadyInCart = cartProducts.find((p) => p.id === productId)

    try {
      if (alreadyInCart) {
        await this.props.addToCart({ ...product, units: alreadyInCart.units + 1 }, this.props.selectedLanguage.key)
        scrollToTop()
        return toast.success(T.add_product)
      }
      await this.props.addToCart(product, this.props.selectedLanguage.key)
      toast.success(T.add_product)
      scrollToTop()
    } catch (err) {
      captureException(err)
    }
  }

  render() {
    const { open, column, addColumn, products = [], selectedLanguage, selectedCurrency, vatType } = this.props
    const T = Translation(selectedLanguage)
    return products.map((prod) => {
      const p = processProductDetails(prod, selectedLanguage.key)
      if (column) {
        return (
          <div key={p.slug} className={`single-product ${addColumn ? 'col-md-4' : null}`}>
            <div className="product-img">
              <Link href="/[lang]/products/[productID]" as={`/${selectedLanguage.key}/products/${p.slug}`}>
                <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>
                  <LazyLoadImage
                    className="primary-img"
                    src={getPrimaryImage(p.images)}
                    alt="FB'S Product"
                  />
                </a>
              </Link>
              {p.isNew && (
                <div className="sticker">
                  <span>{T.new}</span>
                </div>
              )}
              {p.oldPriceWithVat && (
                <div className="sticker-2">
                  <span>{calculateDiscount(p)}</span>
                </div>
              )}
            </div>
            <div className="product-content">
              <h2 className="product-name">
                <Link href="/[lang]/products/[productID]" as={`/${selectedLanguage.key}/products/${p.slug}`}>
                  <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>
                    {/* {p.brand && <span>{p.brand.name} | </span>} */}
                    <span>{p.name}</span>
                  </a>
                </Link>
              </h2>
              <span className="my-2 d-block">
                <Stars size={20} value={p.averageRating} edit={false} />
              </span>
              <div className="price-box">
                <span className="new-price">{getPrice(p, selectedCurrency, false, vatType)}</span>
                {p.oldPriceWithVat ? (
                  <span className="old-price">{getPrice(p, selectedCurrency, true, vatType)}</span>
                ) : null}
              </div>
              <div className="product-action">
                <ul className="product-action-link">
                  {p.available ? (
                    <li className="shopping-cart_link" title="Add to Cart">
                      <a className="w-100 overflow-hidden" onClick={() => this.addToCart(p.id)}>
                        <i className="ion-bag" />
                      </a>
                    </li>
                  ) : (
                    <li className="shopping-cart_link" title="Add to Cart">
                      <a className="disabled-ion-bag w-100 overflow-hidden">
                        <i className="ion-bag" />
                      </a>
                    </li>
                  )}
                  <li className="quick-view-btn" title="View Product">
                    <a className="w-100 overflow-hidden" onClick={() => open(p)}>
                      <i className="ion-eye" />
                    </a>
                  </li>
                  <li className="single-product_link" title="Add to Wishlist">
                    <a className="w-100 overflow-hidden" onClick={() => this.addToWishlist(p.id)}>
                      <i className="ion-heart" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div key={p.slug} className="row w-100 d-flex align-items-center">
          <div className="col-lg-5 col-md-5 col-sm-6">
            <div className="single-product">
              <div className="product-img">
                <Link href="/[lang]/products/[productID]" as={`/${selectedLanguage.key}/products/${p.slug}`}>
                  <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>
                    <LazyLoadImage
                      className="primary-img"
                      src={getPrimaryImage(p.images)}
                      alt="FB'S Product"
                    />
                    <LazyLoadImage
                      className="secondary-img"
                      src={getPrimaryImage(p.images)}
                      alt="FB'S Product"
                    />
                  </a>
                </Link>
                {p.isNew && (
                  <div className="sticker">
                    <span>{T.new}</span>
                  </div>
                )}
                {p.oldPriceWithVat && (
                  <div className="sticker-2">
                    <span>{calculateDiscount(p)}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-6">
            <div className="product-content product-content-list_view">
              <h2 className="product-name">
                <Link href="/[lang]/products/[productID]" as={`/${selectedLanguage.key}/products/${p.slug}`}>
                  <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>
                    <span>{p.name}</span>
                  </a>
                </Link>
              </h2>
              <span className="my-2 d-block">
                <Stars size={20} value={p.averageRating} edit={false} />
              </span>
              <div className="price-box">
                <span className="new-price">{getPrice(p, selectedCurrency, false, vatType)}</span>
                {p.oldPriceWithVat ? (
                  <span className="old-price">{getPrice(p, selectedCurrency, true, vatType)}</span>
                ) : null}
              </div>
              <div>{parse(p.description)}</div>
              <div className="product-action">
                <ul className="product-action-link">
                  {p.available ? (
                    <li className="shopping-cart_link" title="Add to Cart">
                      <a onClick={() => this.addToCart(p.id)}>
                        <i className="ion-bag" />
                      </a>
                    </li>
                  ) : (
                    <li className="shopping-cart_link" title="Add to Cart">
                      <a className="disabled-ion-bag">
                        <i className="ion-bag" />
                      </a>
                    </li>
                  )}
                  <li className="quick-view-btn">
                    <a onClick={() => open(p)}>
                      <i className="ion-eye" />
                    </a>
                  </li>
                  <li className="single-product_link" title="Add to Wishlist">
                    <a onClick={() => this.addToWishlist(p.id)}>
                      <i className="ion-heart" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
    selectedCurrency: state.currency.selectedCurrency,
    vatType: state.vat.type,
    cartProducts: state.cart.products,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (product, lang) => dispatch(addToCart(product, lang)),
    open: (p) => dispatch(openModal(p)),
    doAddToWishlist: (data, lang) => dispatch(addProductToWishList(data, lang)),
  }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(SingleProduct))
