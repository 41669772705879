import React, { Component } from 'react'
import SpecialProduct from './specialProduct'
import { connect } from 'react-redux'

import loToLower from 'lodash/toLower'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class SpecialCollection extends Component {
  getTranslation(translations) {
    if (!translations) {
      return {}
    }

    const language = this.props.language.selectedLanguage
    const translation = translations.filter((tran) => tran.lang === language.key)

    return translation[0] || {}
  }

  render() {
    let specialCollections = []
    let specialProducts = []
    const { productCollections, selectedCurrency } = this.props

    specialCollections = productCollections.filter((col) => col.collectionType === 'special')

    specialProducts = specialCollections.map((collection) => {
      const translation = this.getTranslation(collection.collectionTranslations)
      return (
        <div
          key={collection.id}
          className={`fb-banner_with_list-product ${loToLower(
            collection.name.trim(),
          )}-product pt-60 pb-60 fb-banner_with_list-product large-appliances_product`}
        >
          <div className="container w-100">
            <div className="fb-product_list_nav">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-5">
                  <div className="fb-section_title-2">
                    <h2>{translation.name}</h2>
                  </div>
                  <div className="fb-banner fb-img-hover-effect special">
                    {/* <Link href="/?a"> */}
                    <a>
                      <LazyLoadImage
                        src={collection.imageUrl} alt="FB'S Banner"
                      />
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-7 special">
                  <div className="btn-group" style={{ height: 52 }}>
                    <button className="subcategories-trigger" type="button">
                      <i className="fa fa-bars" />
                    </button>
                  </div>
                  <SpecialProduct
                    products={collection.products}
                    selectedCurrency={selectedCurrency}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    return specialProducts
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(SpecialCollection)
