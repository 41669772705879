import React, { Component } from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import Loader from '../loader'
import { openModal } from '@store/actions/modal'
import Stars from 'react-stars'
import { addToCart } from '@store/actions/cart'
import Translation from '@translation'
import dynamic from 'next/dynamic'
import { processProductDetails, getPrice, getPrimaryImage, scrollToTop } from 'helpers/helperFuncs'
import { toast } from 'react-toastify'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const OwlCarousel = dynamic(() => import('react-owl-carousel'), { ssr: false })

class SpecialProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardOpen: false,
    }
  }

  addToCart(id) {
    const { selectedLanguage } = this.props
    const T = Translation(selectedLanguage)
    const productId = id
    const sessionId = localStorage.getItem('sessionId')

    const product = {
      units: 1,
      productId,
      sessionId,
    }

    const { cartProducts } = this.props

    const alreadyInCart = cartProducts.find((p) => p.slug === productId && p.units === 1)

    if (alreadyInCart) {
      toast.warning(T.product_in_cart)
    } else {
      this.props.addToCart(product, this.props.selectedLanguage.key)
      toast.success(T.add_product)
    }

    this.setState({ cardOpen: true })

    scrollToTop()
  }

  render() {
    const { products, selectedCurrency, selectedLanguage, open, user, vatType } = this.props
    const T = Translation(selectedLanguage)
    this.state.cardOpen ? this.toggleCart() : null

    if (!products) {
      return (
        <>
          <div className="col-lg-12 col-sm-6">
            <Loader />
          </div>
          <div className="col-lg-12 col-sm-6">
            <Loader />
          </div>
        </>
      )
    }
    const productElements = products.map((prod) => {
      const p = processProductDetails(prod, selectedLanguage.key)

      return (
        <div className="grid-item" key={`spd  -${p.slug}`}>
          <div className="single-product list-single_product">
            <div className="product-img list-product_img">
              <Link as={`/${selectedLanguage.key}/products/${p.slug}`} href={`/[lang]/products/${p.id}`}>
                <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>
                  <LazyLoadImage
                    className="primary-img" src={getPrimaryImage(p.images)} alt="FB'S Prduct"
                  />
                </a>
              </Link>
              {p.isNew && (
                <div className="sticker">
                  <span>{T.new}</span>
                </div>
              )}
            </div>
            <div className="product-content list-product_content special">
              <h2 className="product-name">
                <Link as={`/${selectedLanguage.key}/products/${p.slug}`} href={`/[lang]/products/${p.id}`}>
                  <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>{p.name}</a>
                </Link>
              </h2>
              <span className="my-2 d-block">
                <Stars size={20} value={p.averageRating} edit={false} />
              </span>
              <div className="price-box special-product">
                <span className="new-price">{getPrice(p, selectedCurrency, false, vatType)}</span>
                {p.oldPriceWithVat && <span className="old-price">{getPrice(p, selectedCurrency, true, vatType)}</span>}
              </div>
              <p className="mob-price old-price special-product price-box d-none">
                {p.oldPriceWithVat && getPrice(p, selectedCurrency, true, vatType)}
              </p>
              <div className="product-action">
                <ul className="product-action-link">
                  {p.available ? (
                    <li className="shopping-cart_link">
                      <a onClick={() => this.addToCart(p.id)}>
                        <i className="ion-bag" />
                      </a>
                    </li>
                  ) : (
                    <li className="shopping-cart_link">
                      <a className="disabled-ion-bag">
                        <i className="ion-bag" />
                      </a>
                    </li>
                  )}
                  <li className="quick-view-btn">
                    <Link href="/">
                      <a title="Quick View" data-toggle="modal" data-target="#exampleModalCenter">
                        <i className="ion-eye" onClick={() => open(p, selectedLanguage.key, user.token)} />
                        <i className="ion-eye" />
                      </a>
                    </Link>
                  </li>
                  <li className="single-product_link" title="Add to Wishlist">
                    <Link as={`/${selectedLanguage.key}/products/${p.slug}`} href="/[lang]/products/[productID]">
                      <a d-href={`/${selectedLanguage.key}/products/${p.slug}`}>
                        <i className="ion-heart" />
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    })

    return (
      <OwlCarousel
        className="fb-product_active  owl-carousel ware specialProducts"
        stageClass="ware-stage"
        stageOuterClass="ware-stage-outer"
        lazyLoad
        autoplayTimeout={5000}
        dots
        margin={0}
        items={4}
        navText={['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>']}
        responsive={{
          0: {
            items: 1,
          },
          256: {
            items: 2,
          },
        }}
      >
        {productElements}
      </OwlCarousel>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
    cartProducts: state.cart.products,
    user: state.login.user,
    vatType: state.vat.type,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    open: (product, lang, token) => dispatch(openModal(product, lang, token)),
    addToCart: (product, lang) => dispatch(addToCart(product, lang)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialProduct)
